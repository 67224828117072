//type "make app" to upload folders to public link (must on internal network)
//type "gcloud app deploy" to upload app to google gcloud
//type "npm start" to run sandbox

import React from 'react';
import ReactDOM from 'react-dom';
import '../App.css';
import MSData from './ms.json';

var switches = [
  {
    model: 9300,
    stacking: true,
    uplink: 10,
    uplink2: 40,
    mgig: true,
    capability: 3,
    stackPower: true,
    modularPower: true,
    poe: 60,
    rpsu: true,
    rfan: true
  }, {
    model: '9300L',
    stacking: true,
    uplink: 10,
    mgig: true,
    capability: 3,
    stackPower: false,
    modularPower: true,
    poe: 60,
    rpsu: true,
    rfan: true
  }, {
    model: '9300X',
    stacking: true,
    uplink: 10,
    uplink2: 40,
    mgig: true,
    capability: 3,
    stackPower: true,
    modularPower: true,
    poe: 90,
    rpsu: true,
    rfan: true
  }, {
    model: 120,
    stacking: false,
    uplink: 1,
    mgig: false,
    capability: 2,
    modularPower: false,
    poe: 30,
    rpsu: null,
    rfan: null
  }, {
    model: 125,
    stacking: false,
    uplink: 10,
    mgig: false,
    capability: 2,
    modularPower: false,
    poe: 30,
    rpsu: null,
    rfan: null
  }, {
    model: 130,
    stacking: false,
    uplink: 1,
    uplink2: 10,
    mgig: true,
    capability: 2,
    ruggedized: false,
    modularPower: false,
    poe: 30,
    rpsu: null,
    rfan: null
  }, {
    model: 150,
    stacking: true,
    uplink: 1,
    uplink2: 10,
    mgig: true,
    capability: 2,
    ruggedized: false,
    modularPower: false,
    poe: 60,
    rpsu: null,
    rfan: null
  }, {
    model: '130R',
    stacking: false,
    uplink: 1,
    mgig: false,
    capability: 2,
    ruggedized: true,
    modularPower: false,
    poe: 30,
    rpsu: null,
    rfan: null
  }, {
    model: 210,
    stacking: true,
    uplink: 1,
    mgig: false,
    capability: 2,
    modularPower: false,
    poe: 30,
    rpsu: null,
    rfan: null
  }, {
    model: 225,
    stacking: true,
    uplink: 10,
    mgig: false,
    capability: 2,
    modularPower: false,
    poe: 30,
    rpsu: null,
    rfan: null
  }, {
    model: 250,
    stacking: true,
    uplink: 10,
    mgig: false,
    capability: 3,
    modularPower: true,
    poe: 30,
    rpsu: true,
    rfan: null
  }, {
    model: 350,
    stacking: true,
    uplink: 10,
    mgig: true,
    capability: 3,
    modularPower: true,
    poe: 60,
    rpsu: true,
    rfan: true
  }, {
    model: 355,
    stacking: true,
    uplink: 10,
    uplink2: 40,
    mgig: true,
    capability: 3,
    modularPower: true,
    poe: 60,
    rpsu: true,
    rfan: true
  }, {
    model: 390,
    stacking: true,
    uplink: 10,
    uplink2: 40,
    mgig: true,
    capability: 3,
    stackPower: true,
    modularPower: true,
    poe: 60,
    rpsu: true,
    rfan: true
  }, {
    model: 410,
    stacking: true,
    uplink: 10,
    mgig: false,
    capability: 3,
    modularPower: true,
    poe: 0,
    rpsu: true,
    rfan: true
  }, {
    model: 425,
    stacking: true,
    uplink: 40,
    mgig: false,
    capability: 3,
    modularPower: true,
    poe: 0,
    rpsu: true,
    rfan: true
  }, {
    model: 450,
    stacking: true,
    uplink: 100,
    mgig: false,
    capability: 3,
    modularPower: true,
    poe: 0,
    rpsu: true,
    rfan: true
  }
]

class SwitchPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stacking: null,
      stackSize: null,
      uplink: null,
      transceiver: null,
      mgig: null,
      mgigSpeed: null,
      switchModel: null,
      wishVisible: null,
      capability: null,
      stackPowerSize: null,
      stackPower: null,
      poe: null,
      poeType: 0,
      ruggedized: null,
      rpsu: null,
      rfan: null,
      license: null,
      licenseTerm: null,

      clickedSwitchModel: null,
      clickedSwitchModelAccess: null,
      clickedSwitchModelUplinks: null,
      clickedSwitchModelPSU: null,
      clickedSwitchModelPOE: null,
      clickedSwitchModelPortCount: null,
      clickedSwitchModelCapability: null,
      clickedSwitchModelTemp: null,
      clickedUplinkModule: null,
      clickedUplinkModuelSKU: null
    };
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ SWITCHES @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  renderSwitchInfo(header, switchInfo) {
    return (<div>
      {
        //Hide headers until a switch model is clicked
        switchInfo == null
          ? ''
          : <p className='switchInfo'>
            <h6 className='switchInfoHeader'>{header + ': '}</h6>
            {switchInfo}</p>
      }
    </div>)
  }

  renderSwitchModelDetails(model, access, uplinks, poe, mgig, psu, poeStandard, portCount, switchCapability, ruggedized) {
    return (<div onClick={

      this.state.clickedSwitchModel != model
        ? () => this.setState({
          clickedSwitchModel: model,
          clickedSwitchModelAccess: access,
          clickedSwitchModelUplinks: uplinks,
          clickedSwitchModelPSU: psu,
          clickedSwitchModelPOE: poeStandard,
          clickedSwitchModelPortCount: portCount,
          clickedSwitchModelCapability: switchCapability,
          clickedSwitchModelTemp: ruggedized
        })
        : () => this.setState({
          clickedSwitchModel: null,
          clickedSwitchModelAccess: null,
          clickedSwitchModelUplinks: null,
          clickedSwitchModelPSU: null,
          clickedSwitchModelPOE: null,
          clickedSwitchModelPortCount: null,
          clickedSwitchModelCapability: null,
          clickedSwitchModelTemp: null,
          clickedUplinkModule: null,
          clickedUplinkSKU: null,
          clickedSwitchModelRuggedized: null
        })

    } class="switchModelDetailContainer">
      {
        // Switch model choices filtering
        this.state.poe == true && poe == 0
          ? ""
          : this.state.ruggedized != null && ruggedized == null
            ? ""
            : this.state.poeType <= poe && this.state.mgigSpeed <= mgig
              ? <a className={this.state.clickedSwitchModel == model
                ? 'switchModelDetailSelected'
                : 'switchModelDetail'}>{model}</a>
              : null
      }
    </div>)
  }

  // not being used
  resetVariables() {
    return {
      clickedSwitchModel: null,
      clickedSwitchModelAccess: null,
      clickedSwitchModelUplinks: null,
      clickedSwitchModelPSU: null,
      clickedSwitchModelPOE: null,
      clickedSwitchModelPortCount: null,
      clickedSwitchModelCapability: null,
      clickedSwitchModelTemp: null,
      clickedUplinkModule: null,
      clickedUplinkSKU: null,
      clickedSwitchModelRuggedized: null
    };
  }

  renderUplinkModules(model, sku) {
    return (<div onClick={this.state.clickedUplinkModule != model
      ? () => this.setState({ clickedUplinkModule: model, clickedUplinkSKU: sku })
      : () => this.setState({ clickedUplinkModule: null, clickedUplinkSKU: null })} class="switchModelDetailContainer">
      {
        this.state.clickedSwitchModel != null
          ? <a className={this.state.clickedUplinkModule == model
            ? 'switchModelDetailSelected'
            : 'switchModelDetail'}>{model}</a>
          : ""
      }
    </div>)
  }

  renderStackingCableRecommednations(stackSpeed) {
    return (<div>
      {
        this.state.stackSize > 5
          ? <div>
            <p>{this.state.stackSize - 1 + ' x MA-CBL-' + stackSpeed + 'G-50cm'}</p>
            <p>{'1 x MA-CBL-' + stackSpeed + 'G-3m'}</p>
          </div>
          : this.state.stackSize > 2
            ? <div>
              <p>{this.state.stackSize - 1 + ' x MA-CBL-' + stackSpeed + 'G-50cm'}</p>
              <p>{'1 x MA-CBL-' + stackSpeed + 'G-1m'}</p>
            </div>
            : this.state.stackSize == 2
              ? <div>
                <p>{'2 x MA-CBL-' + stackSpeed + 'G-50cm'}</p>
              </div>
              : ""
      }
    </div>)
  }

  render9300StackingCableRecommednations() {
    return (<div>
      {
        this.state.stackSize > 5
          ? <div>
            <p>{this.state.stackSize - 1 + ' x STACK-T1-50CM-M'}</p>
            <p>{'1 x STACK-T1-3M-M'}</p>
          </div>
          : this.state.stackSize > 2
            ? <div>
              <p>{this.state.stackSize - 1 + ' x STACK-T1-50CM-M'}</p>
              <p>{'1 x STACK-T1-1M-M'}</p>
            </div>
            : this.state.stackSize == 2
              ? <div>
                <p>{'2 x STACK-T1-50CM-M'}</p>
              </div>
              : ""
      }
    </div>)
  }
  render9300LStackingCableRecommednations() {
    return (<div>
      {
        this.state.stackSize > 5
          ? <div>
            <p>{this.state.stackSize - 1 + ' x STACK-T3A-50CM-M'}</p>
            <p>{'1 x STACK-T3A-3M-M'}</p>
          </div>
          : this.state.stackSize > 2
            ? <div>
              <p>{this.state.stackSize - 1 + ' x STACK-T3A-50CM-M'}</p>
              <p>{'1 x STACK-T3A-1M-M'}</p>
            </div>
            : this.state.stackSize == 2
              ? <div>
                <p>{'2 x STACK-T3A-50CM-M'}</p>
              </div>
              : ""
      }
    </div>)
  }

  renderStackPowerCableRecommednations() {
    return (<div>
      {
        this.state.stackPowerSize == 4
          ? <div>
            <p>{'1 x MA-CBL-SPWR-150CM'}</p>
            <p>{'3 x MA-CBL-SPWR-30CM'}</p>
          </div>
          : this.state.stackPowerSize == 3
            ? <div>
              <p>{'1 x MA-CBL-SPWR-150CM'}</p>
              <p>{'2 x MA-CBL-SPWR-30CM'}</p>
            </div>
            : this.state.stackPowerSize == 2
              ? <div>
                <p>{'2 x MA-CBL-SPWR-30CM'}</p>
              </div>
              : ""
      }
    </div>)
  }

  render9300StackPowerCableRecommednations() {
    return (<div>
      {
        this.state.stackPowerSize == 4
          ? <div>
            <p>{'1 x MA-CAB-SPWR-150CM'}</p>
            <p>{'3 x MA-CAB-SPWR-30CM'}</p>
          </div>
          : this.state.stackPowerSize == 3
            ? <div>
              <p>{'1 x MA-CAB-SPWR-150CM'}</p>
              <p>{'2 x MA-CAB-SPWR-30CM'}</p>
            </div>
            : this.state.stackPowerSize == 2
              ? <div>
                <p>{'2 x MA-CAB-SPWR-30CM'}</p>
              </div>
              : ""
      }
    </div>)
  }

  renderLicenseSKU(model) {
    return (<div>
      {
        this.state.licenseTerm == null && this.state.license != null && this.state.switchModel != 390
          ? <p>{'1 x LIC-' + this.state.clickedSwitchModel + '-xYR (x = 1, 3, 5, 7, 10 years)'}</p>
          : this.state.licenseTerm == null && this.state.license != null && this.state.switchModel == 390
            ? <p>{'1 x LIC-MS390-' + this.state.clickedSwitchModelPortCount + this.state.license + '-xYR (x = 1, 3, 5, 7, 10 years)'}</p>
            : this.state.licenseTerm != null && this.state.switchModel == 390
              ? <p>{'1 x LIC-MS390-' + this.state.clickedSwitchModelPortCount + this.state.license + '-' + this.state.licenseTerm + 'YR'}</p>
              : this.state.licenseTerm != null
                ? <p>{'1 x LIC-' + this.state.clickedSwitchModel + '-' + this.state.licenseTerm + 'YR'}</p>
                : null
      }
    </div>)
  }

  renderMS130LicenseSKU(model) {
    if (this.state.clickedSwitchModel == 'MS130-8' || this.state.clickedSwitchModel == 'MS130-8P' || this.state.clickedSwitchModel == 'MS130-8X' || this.state.clickedSwitchModel == 'MS130-12X' || this.state.clickedSwitchModel == 'MS130R-8P') {
      if (this.state.licenseTerm == null && this.state.license != null) {
        return <p>{'1 x LIC-MS130-CMPT' + '-xY (x = 1, 3, 5, 7, 10 years)'}</p>;
      }
      if (this.state.licenseTerm != null) {
        return <p>{'1 x LIC-MS130-CMPT' + '-' + this.state.licenseTerm + 'Y'}</p>;
      }
      else {
        return null;
      }
    }
    else {
      if (this.state.licenseTerm == null && this.state.license != null) {
        return <p>{'1 x LIC-MS130-' + this.state.clickedSwitchModelPortCount + '-xY (x = 1, 3, 5, 7, 10 years)'}</p>;
      }
      if (this.state.licenseTerm != null) {
        return <p>{'1 x LIC-MS130-' + this.state.clickedSwitchModelPortCount + '-' + this.state.licenseTerm + 'Y'}</p>;
      }
      else {
        return null;
      }
    }
  }

  renderMS9300LicenseSKU() {
    if (this.state.licenseTerm == null && this.state.license != null && this.state.clickedSwitchModelPortCount == 12) {
      return <p>{'1 x LIC-C9300-24' + this.state.license + '-xY (x = 1, 3, 5, 7, 10 years)'}</p>;
    }
    if (this.state.licenseTerm == null && this.state.license != null) {
      return <p>{'1 x LIC-C9300-' + this.state.clickedSwitchModelPortCount + this.state.license + '-xY (x = 1, 3, 5, 7, 10 years)'}</p>;
    }
    if (this.state.licenseTerm != null && this.state.clickedSwitchModelPortCount == 12) {
      return <p>{'1 x LIC-C9300-24' + this.state.license + '-' + this.state.licenseTerm + 'Y'}</p>;
    }
    if (this.state.licenseTerm != null) {
      return <p>{'1 x LIC-C9300-' + this.state.clickedSwitchModelPortCount + this.state.license + '-' + this.state.licenseTerm + 'Y'}</p>;
    }
    else {
      return null;
    }
  }

  renderTransceiverSKU() {
    if (this.state.transceiver != null) {
      return <p>{'? x ' + this.state.transceiver}</p>;
    }
    else {
      return null;
    }
  }

  renderClickedUplinkSKU() {
    if (this.state.clickedUplinkSKU != null) {
      return <p>{this.state.clickedUplinkSKU}</p>;
    } else {
      return null;
    }
  }

  renderClickedSwitchModelPSU() {
    if (this.state.clickedSwitchModelPSU != null) {
      return <p>{this.state.clickedSwitchModelPSU}</p>;
    } else {
      return null;
    }
  }

  renderSwitchModel(model) {
    return (<div className={model + ' switchPictureContainer'}>
      <div onClick={() => {
        if (this.state.switchModel !== model) {
          this.setState({
            switchModel: model,
            clickedSwitchModel: null,
            clickedSwitchModelAccess: null,
            clickedSwitchModelUplinks: null,
            clickedSwitchModelPSU: null,
            clickedSwitchModelPOE: null,
            clickedSwitchModelPortCount: null,
            clickedSwitchModelCapability: null,
            clickedSwitchModelTemp: null,
            clickedUplinkModule: null,
            clickedUplinkSKU: null,
            clickedSwitchModelRuggedized: null
          });
        } else {
          this.setState({
            switchModel: null, clickedSwitchModel: null,
            clickedSwitchModelAccess: null,
            clickedSwitchModelUplinks: null,
            clickedSwitchModelPSU: null,
            clickedSwitchModelPOE: null,
            clickedSwitchModelPortCount: null,
            clickedSwitchModelCapability: null,
            clickedSwitchModelTemp: null,
            clickedUplinkModule: null,
            clickedUplinkSKU: null,
            clickedSwitchModelRuggedized: null
          });
        }
      }} className='switchModel'>

        {
          model == 9300
            ? <h6 className={this.state.switchModel == model
              ? 'selectedSwitchHeader'
              : 'switchHeader'}>Catalyst {model + '-M'}</h6>

            : model == '9300X'
              ? <h6 className={this.state.switchModel == model
                ? 'selectedSwitchHeader'
                : 'switchHeader'}>Catalyst 9300X-M</h6>

              : model == '9300L'
                ? <h6 className={this.state.switchModel == model
                  ? 'selectedSwitchHeader'
                  : 'switchHeader'}>Catalyst 9300L-M</h6>

                : <h6 className={this.state.switchModel == model
                  ? 'selectedSwitchHeader'
                  : 'switchHeader'}>MS{model}</h6>
        }


        {
          this.state.clickedUplinkModule != null && model == 390 && this.state.switchModel == 390 || this.state.clickedUplinkModule != null && model == 9300 && this.state.switchModel == 9300 || this.state.clickedUplinkModule != null && model == '9300X' && this.state.switchModel == '9300X'
            ? <img src={'/img/ms/' + model + '_' + this.state.clickedSwitchModelPortCount + '_' + this.state.clickedUplinkModule + '.png'} alt={model} className='modelIconFull modelIcon' />
            : this.state.switchModel == model && this.state.clickedSwitchModel != null
              ? <img src={'/img/ms/' + model + '_' + this.state.clickedSwitchModelPortCount + '.png'} alt={'MS' + model} className='modelIconFull modelIcon' />
              : <img src={'/img/ms/' + model + '.png'} alt={'MS' + model} className='modelIconFull modelIcon' />
        }

      </div>
      <div className={'switchInfo'}>
        {
          this.state.switchModel == 9300 && model == this.state.switchModel
            ? <div><div className="switchInfoSelected">
              {this.renderSwitchInfo('Please select a model', '')}
              {/* renderSwitchModelDetails: model, access, uplinks, poe, mgig, psu, poeStandard, portCount, switchCapability, ruggedized */}
              {this.renderSwitchModelDetails('C9300-24S-M', '24 x 1G SFP', 'Modular', 0, 0, '1 x PWR-C1-715WAC-P-M', 'NA', 24, 'L3', null)}
              {this.renderSwitchModelDetails('C9300-24T-M', '24 x 1G', 'Modular', 0, 0, '1 x PWR-C1-350WAC-P-M', 'NA', 24, 'L3', null)}
              {this.renderSwitchModelDetails('C9300-24P-M', '24 x 1G', 'Modular', 30, 0, '1 x PWR-C1-715WAC-P-M', 'PoE+', 24, 'L3', null)}
              {this.renderSwitchModelDetails('C9300-24U-M', '24 x 1G', 'Modular', 60, 0, '1 x PWR-C1-1100WAC-P-M', 'UPoE', 24, 'L3', null)}
              {this.renderSwitchModelDetails('C9300-24UX-M', '24 x 10G', 'Modular', 60, 10, '1 x PWR-C1-1100WAC-P-M ', 'UPoE', 24, 'L3', null)}
              {this.renderSwitchModelDetails('C9300-48S-M', '48 x 1G SFP', 'Modular', 0, 0, '1 x PWR-C1-715WAC-P-M', 'NA', 48, 'L3', null)}
              {this.renderSwitchModelDetails('C9300-48T-M', '48 x 1G', 'Modular', 0, 0, '1 x PWR-C1-350WAC-P-M', 'NA', 48, 'L3', null)}
              {this.renderSwitchModelDetails('C9300-48P-M', '48 x 1G', 'Modular', 30, 0, '1 x PWR-C1-715WAC-P-M', 'PoE+', 48, 'L3', null)}
              {this.renderSwitchModelDetails('C9300-48U-M', '48 x 1G', 'Modular', 60, 0, '1 x PWR-C1-1100WAC-P-M ', 'UPoE', 48, 'L3', null)}
              {this.renderSwitchModelDetails('C9300-48UXM-M', '36 x 2.5G + 12 x 10G', 'Modular', 60, 10, '1 x MPWR-C1-1100WAC-P-M ', 'UPoE', 48, 'L3', null)}
              {this.renderSwitchModelDetails('C9300-48UN-M', '48 x 5G', 'Modular', 60, 5, '1 x PWR-C1-1100WAC-P-M', 'UPoE', 48, 'L3', null)}
              {
                this.state.clickedSwitchModel == null
                  ? null
                  : <div>
                    {this.renderSwitchInfo('Please select an uplink module', "")}
                    {/* {this.renderUplinkModules('2 x 10G', '1 x C9300-NM-2Y-M')} */}
                    {this.renderUplinkModules('8 x 10G', '1 x C9300-NM-8X-M')}
                    {this.renderUplinkModules('2 x 25G', '1 x C9300-NM-2Y-M')}
                    {this.renderUplinkModules('2 x 40G', '1 x C9300-NM-2Q-M')}
                  </div>
              }

              {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
              {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
              {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
              {
                this.state.clickedSwitchModel == null
                  ? null
                  : <div>

                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                    <p>{'1 x ' + this.state.clickedSwitchModel}</p>
                    {this.renderMS9300LicenseSKU()}
                    {this.renderTransceiverSKU()}
                    {this.renderClickedUplinkSKU()}
                    {this.render9300StackingCableRecommednations()}
                    {this.render9300StackPowerCableRecommednations()}
                    {
                      this.state.rpsu != true
                        ? null
                        : this.renderClickedSwitchModelPSU()
                    }
                  </div>
              }
            </div>
              <div className='datasheetContainer'>
                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/Catalyst_9300-M_datasheet">Datasheet</a>
                <p className='datasheetDivider'> | </p>
                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/Catalyst_9300-M_Series_Installation_Guide">Installation</a>
              </div>
            </div>

            : this.state.switchModel == '9300L' && model == this.state.switchModel
              ? <div><div className="switchInfoSelected">
                {this.renderSwitchInfo('Please select a model', '')}
                {/* renderSwitchModelDetails: model, access, uplinks, poe, mgig, psu, poeStandard, portCount, switchCapability, ruggedized */}
                {this.renderSwitchModelDetails('C9300L-24T-4X-M', '24 x 1G', '4 × 10G SFP+', 0, null, '1 x PWR-C1-350WAC-P-M', 'NA', 24, 'L3', null)}
                {this.renderSwitchModelDetails('C9300L-24P-4X-M', '24 x 1G', '4 × 10G SFP+', 30, null, '1 x PWR-C1-715WAC-P-M', 'PoE+', 24, 'L3', null)}
                {this.renderSwitchModelDetails('C9300L-24UXG-4X-M', '8 x 100M/1G/2.5G/5G/10G + 16 x 10M/100M/1G', '4 × 10G SFP+', 60, 10, '1 x PWR-C1-1100WAC-P-M', 'UPoE', 24, 'L3', null)}
                {this.renderSwitchModelDetails('C9300L-48T-4X-M', '48 x 1G', '4 × 10G SFP+', 0, null, '1 x PWR-C1-350WAC-P-M', 'NA', 48, 'L3', null)}
                {this.renderSwitchModelDetails('C9300L-48P-4X-M', '48 x 1G', '4 × 10G SFP+', 30, null, '1 x PWR-C1-715WAC-P-M', 'PoE+', 48, 'L3', null)}
                {this.renderSwitchModelDetails('C9300L-48PF-4X-M', '48 x 1G', '4 × 10G SFP+', 30, null, '1 x PWR-C1-1100WAC-P-M', 'PoE+', 48, 'L3', null)}
                {this.renderSwitchModelDetails('C9300L-48UXG-4X-M', '12 x 100M/1G/2.5G/5G/10G + 36 x 10M/100M/1G', '4 × 10G SFP+', 60, 10, '1 x PWR-C1-1100WAC-P-M', 'UPoE', 48, 'L3', null)}

                {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                {
                  this.state.clickedSwitchModel == null
                    ? null
                    : <div>

                      <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                      <p>{'1 x ' + this.state.clickedSwitchModel}</p>
                      {this.renderMS9300LicenseSKU()}
                      {this.renderTransceiverSKU()}
                      {
                        this.state.stacking != true
                          ? null
                          : <p>1 x C9300L-STACK-KIT2-M</p>
                      }
                      {this.render9300LStackingCableRecommednations()}
                      {this.render9300StackPowerCableRecommednations()}
                      {
                        this.state.rpsu != true
                          ? null
                          : this.renderClickedSwitchModelPSU()
                      }
                    </div>
                }
              </div>
                <div className='datasheetContainer'>
                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/Catalyst_9300L-M_Datasheet">Datasheet</a>
                  <p className='datasheetDivider'> | </p>
                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/Catalyst_9300-M_Series_Installation_Guide">Installation</a>
                </div>
              </div>

              : this.state.switchModel == '9300X' && model == this.state.switchModel
                ? <div><div className="switchInfoSelected">
                  {this.renderSwitchInfo('Please select a model', '')}
                  {/* renderSwitchModelDetails: model, access, uplinks, poe, mgig, psu, poeStandard, portCount, switchCapability, ruggedized */}
                  {this.renderSwitchModelDetails('C9300X-12Y-M', '12 x 1/10/25G SFP28', 'Modular', 0, 0, '1 x PWR-C1-715WAC-P-M', 'NA', 12, 'L3', null)}
                  {this.renderSwitchModelDetails('C9300X-24Y-M', '24 x 1/10/25G SFP28', 'Modular', 0, 0, '1 x PWR-C1-715WAC-P-M', 'NA', 24, 'L3', null)}
                  {this.renderSwitchModelDetails('C9300X-24HX-M', '24 x 100M/1G/2.5G/5G/10G', 'Modular', 90, 10, '1 x PWR-C1-1100WAC-P-M', 'UPoE+', 24, 'L3', null)}
                  {this.renderSwitchModelDetails('C9300X-48TX-M', '48 x 100M/1G/2.5G/5G/10G', 'Modular', 0, 10, '1 x PWR-C1-1100WAC-P-M ', 'NA', 48, 'L3', null)}
                  {this.renderSwitchModelDetails('C9300X-48HX-M', '48 x 100M/1G/2.5G/5G/10G', 'Modular', 90, 10, '1 x PWR-C1-1100WAC-P-M', 'UPoE+', 48, 'L3', null)}
                  {this.renderSwitchModelDetails('C9300X-48HXN-M', '48 x 100M/1G/2.5G/5G/10G', 'Modular', 90, 10, '1 x PWR-C1-1100WAC-P-M', 'UPoE+', 48, 'L3', null)}
                  {
                    this.state.clickedSwitchModel == null
                      ? null
                      : <div>
                        {this.renderSwitchInfo('Please select an uplink module', "")}
                        {this.renderUplinkModules('8 x 25G', '1 x C9300X-NM-8Y-M')}
                        {this.renderUplinkModules('2 x 100G', '1 x C9300X-NM-2C-M')}
                      </div>
                  }

                  {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                  {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                  {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                  {
                    this.state.clickedSwitchModel == null
                      ? null
                      : <div>

                        <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                        <p>{'1 x ' + this.state.clickedSwitchModel}</p>
                        {this.renderMS9300LicenseSKU()}
                        {this.renderTransceiverSKU()}
                        {this.renderClickedUplinkSKU()}
                        {this.render9300StackingCableRecommednations()}
                        {this.render9300StackPowerCableRecommednations()}
                        {
                          this.state.rpsu != true
                            ? null
                            : this.renderClickedSwitchModelPSU()
                        }
                      </div>
                  }
                </div>
                  <div className='datasheetContainer'>
                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/Catalyst_9300X-M_Datasheet">Datasheet</a>
                    <p className='datasheetDivider'> | </p>
                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/Catalyst_9300-M_Series_Installation_Guide">Installation</a>
                  </div>
                </div>

                : this.state.switchModel == 120 && model == this.state.switchModel
                  ? <div><div className="switchInfoSelected">
                    {this.renderSwitchInfo('Please select a model', '')}
                    {this.renderSwitchModelDetails('MS120-8', '8 × 1G', '2 × 1G SFP', 0, null, '1 x MA-PWR-30WAC', 'NA', 8, 'L2', null)}
                    {this.renderSwitchModelDetails('MS120-8LP', '8 × 1G', '2 × 1G SFP', 30, null, '1 x MA-PWR-90WAC', 'PoE+', 8, 'L2', null)}
                    {this.renderSwitchModelDetails('MS120-8FP', '8 × 1G', '2 × 1G SFP', 30, null, 'Fixed Internal', 'PoE+', 8, 'L2', null)}
                    {this.renderSwitchModelDetails('MS120-24', '24 × 1G', '4 × 1G SFP', 0, null, 'Fixed Internal', 'NA', 24, 'L2', null)}
                    {this.renderSwitchModelDetails('MS120-24P', '24 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'PoE+', 24, 'L2', null)}
                    {this.renderSwitchModelDetails('MS120-48', '48 × 1G', '4 × 1G SFP', 0, null, 'Fixed Internal', 'NA', 48, 'L2', null)}
                    {this.renderSwitchModelDetails('MS120-48FP', '48 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'PoE+', 48, 'L2', null)}
                    {this.renderSwitchModelDetails('MS120-48LP', '48 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'PoE+', 48, 'L2', null)}
                    {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                    {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                    {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                    {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                    {
                      this.state.clickedSwitchModel == null
                        ? null
                        : <div>
                          <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                          <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                          {this.renderLicenseSKU(model)}
                          {this.renderTransceiverSKU()}
                        </div>
                    }
                  </div>
                    <div className='datasheetContainer'>
                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/ms120-series-datasheet/?file">Datasheet</a>
                      <p className='datasheetDivider'> | </p>
                      <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS120_Series_Installation_Guide">Installation</a>
                    </div>
                  </div>
                  : this.state.switchModel == 125 && model == this.state.switchModel
                    ? <div><div className="switchInfoSelected">
                      {this.renderSwitchInfo('Please select a model', '')}
                      {this.renderSwitchModelDetails('MS125-24', '24 × 1G', '4 × 10G SFP+', 0, null, 'Fixed Internal', 'NA', 24, 'L2', null)}
                      {this.renderSwitchModelDetails('MS125-24P', '24 × 1G', '4 × 10G SFP+', 30, null, 'Fixed Internal', 'PoE+', 24, 'L2', null)}
                      {this.renderSwitchModelDetails('MS125-48', '48 × 1G', '4 × 10G SFP+', 0, null, 'Fixed Internal', 'NA', 48, 'L2', null)}
                      {this.renderSwitchModelDetails('MS125-48LP', '48 × 1G', '4 × 10G SFP+', 30, null, 'Fixed Internal', 'PoE+', 48, 'L2', null)}
                      {this.renderSwitchModelDetails('MS125-48FP', '48 × 1G', '4 × 10G SFP+', 30, null, 'Fixed Internal', 'PoE+', 48, 'L2', null)}
                      {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                      {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                      {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                      {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                      {
                        this.state.clickedSwitchModel == null
                          ? null
                          : <div>
                            <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                            <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                            {this.renderLicenseSKU(model)}
                            {this.renderTransceiverSKU()}
                          </div>
                      }
                    </div>
                      <div className='datasheetContainer'>
                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/MS125_Overview_and_Specifications">Datasheet</a>
                        <p className='datasheetDivider'> | </p>
                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS125_Series_Installation_Guide">Installation</a>
                      </div>
                    </div>

                    : this.state.switchModel == 130 && model == this.state.switchModel
                      ? <div><div className="switchInfoSelected">
                        {this.renderSwitchInfo('Please select a model', '')}

                        {/* {this.renderSwitchModelDetails('MS130R-8P', '8 × 1G', '2 × 1G SFP', 30, null, 'Fixed Internal', '802.3bt', 8, 'L2', null)} */}
                        {this.renderSwitchModelDetails('MS130-8', '8 × 1G', '2 × 1G SFP', 0, null, 'Fixed Internal', 'NA', 8, 'L2', null)}
                        {this.renderSwitchModelDetails('MS130-8P', '8 × 1G', '2 × 1G SFP', 30, null, 'Fixed Internal', 'PoE+', 8, 'L2', null)}
                        {this.renderSwitchModelDetails('MS130-8X', '6 × 1G, 2 x 2.5G', '2 × 10G SFP+', 30, 2.5, 'Fixed Internal', 'PoE+', 8, 'L2', null)}

                        {this.renderSwitchModelDetails('MS130-12X', '8 × 1G, 4 x 2.5G', '2 × 10G SFP+', 30, 2.5, 'Fixed Internal', 'PoE+', 12, 'L2', null)}

                        {this.renderSwitchModelDetails('MS130-24', '24 × 1G', '4 × 1G SFP', 0, null, 'Fixed Internal', 'NA', 24, 'L2', null)}
                        {this.renderSwitchModelDetails('MS130-24P', '24 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'PoE+', 24, 'L2', null)}
                        {this.renderSwitchModelDetails('MS130-24X', '18 × 1G, 6 x 2.5G', '4 × 10G SFP+', 30, 2.5, 'Fixed Internal', 'PoE+', 24, 'L2', null)}

                        {this.renderSwitchModelDetails('MS130-48', '48 × 1G', '4 × 1G SFP', 0, null, 'Fixed Internal', 'NA', 48, 'L2', null)}
                        {this.renderSwitchModelDetails('MS130-48P', '48 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'PoE+', 48, 'L2', null)}
                        {this.renderSwitchModelDetails('MS130-48X', '40 × 1G, 8 x 2.5G', '4 × 10G SFP+', 30, 2.5, 'Fixed Internal', 'PoE+', 48, 'L2', null)}

                        {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                        {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                        {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                        {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                        {
                          this.state.clickedSwitchModel == null
                            ? null
                            : <div>
                              <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                              <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                              {this.renderMS130LicenseSKU()}
                              {this.renderTransceiverSKU()}
                            </div>
                        }
                      </div>
                        <div className='datasheetContainer'>
                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/MS130_Datasheet">Datasheet</a>
                          <p className='datasheetDivider'> | </p>
                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS130_Series_Installation_Guide">Installation</a>
                        </div>
                      </div>

                      : this.state.switchModel == '130R' && model == this.state.switchModel
                        ? <div><div className="switchInfoSelected">
                          {this.renderSwitchInfo('Please select a model', '')}

                          {this.renderSwitchModelDetails('MS130R-8P', '8 × 1G', '2 × 1G SFP', 30, null, 'Fixed Internal', '802.3bt', 8, 'L2', '-40°F to 158°F')}

                          {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                          {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                          {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                          {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                          {this.renderSwitchInfo('Operating Temp', this.state.clickedSwitchModelTemp)}
                          {
                            this.state.clickedSwitchModel == null
                              ? null
                              : <div>
                                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                {this.renderMS130LicenseSKU()}
                              </div>
                          }
                        </div>
                          <div className='datasheetContainer'>
                            <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/MS130R_Datasheet">Datasheet</a>
                            <p className='datasheetDivider'> | </p>
                            <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS130R_Series_Installation_Guide">Installation</a>
                          </div>
                        </div>

                        : this.state.switchModel == 150 && model == this.state.switchModel
                          ? <div><div className="switchInfoSelected">
                            {this.renderSwitchInfo('Please select a model', '')}

                            {/* renderSwitchModelDetails: model, access, uplinks, poe, mgig, psu, poeStandard, portCount, switchCapability, ruggedized */}
                            {this.renderSwitchModelDetails('MS150-24T-4G', '24 × 1G', '4 × 1G SFP', 0, null, 'Fixed Internal', 'NA', 24, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-24P-4G', '24 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'UPoE', 24, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-24T-4X', '24 × 1G', '4 × 10G SFP+', 0, null, 'Fixed Internal', 'NA', 24, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-24P-4X', '24 × 1G', '4 × 10G SFP+', 30, null, 'Fixed Internal', 'UPoE', 24, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-48T-4X', '48 × 1G', '4 × 10G SFP+', 0, null, 'Fixed Internal', 'NA', 48, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-48LP-4X', '48 × 1G', '4 × 10G SFP+', 30, null, 'Fixed Internal', 'UPoE', 48, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-48FP-4X', '48 × 1G', '4 × 10G SFP+', 30, null, 'Fixed Internal', 'UPoE', 48, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-48T-4G', '48 × 1G', '4 × 1G SFP', 0, null, 'Fixed Internal', 'NA', 48, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-48LP-4G', '48 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'UPoE', 48, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-48FP-4G', '48 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'UPoE', 48, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-24MP-4X', '16 × 1G, 8 x 5G', '4 × 10G SFP+', 60, 5, 'Fixed Internal', 'UPoE', 24, 'L2', null)}
                            {this.renderSwitchModelDetails('MS150-48MP-4X', '32 × 1G, 16 x 5G', '4 × 10G SFP+', 60, 5, 'Fixed Internal', 'UPoE', 48, 'L2', null)}

                            {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                            {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                            {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                            {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                            {
                              this.state.clickedSwitchModel == null
                                ? null
                                : <div>
                                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                  <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                  {this.renderLicenseSKU(model)}
                                  {this.renderTransceiverSKU()}
                                  {this.renderStackingCableRecommednations(100)}
                                </div>
                            }
                          </div>
                            <div className='datasheetContainer'>
                              <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/MS150_Datashee">Datasheet</a>
                              <p className='datasheetDivider'> | </p>
                              <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS150_Series_Installation_Guide">Installation</a>
                            </div>
                          </div>

                          : this.state.switchModel == 210 && model == this.state.switchModel
                            ? <div><div className="switchInfoSelected">
                              {this.renderSwitchInfo('Please select a model', '')}
                              {this.renderSwitchModelDetails('MS210-24', '24 × 1G', '4 × 1G SFP', 0, null, 'Fixed Internal', 'NA', 24, 'L2 with Static Routes', null)}
                              {this.renderSwitchModelDetails('MS210-24P', '24 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'PoE+', 24, 'L2 with Static Routes', null)}
                              {this.renderSwitchModelDetails('MS210-48', '48 × 1G', '4 × 1G SFP', 0, null, 'Fixed Internal', 'NA', 48, 'L2 with Static Routes', null)}
                              {this.renderSwitchModelDetails('MS210-48LP', '48 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'PoE+', 48, 'L2 with Static Routes', null)}
                              {this.renderSwitchModelDetails('MS210-48FP', '48 × 1G', '4 × 1G SFP', 30, null, 'Fixed Internal', 'PoE+', 48, 'L2 with Static Routes', null)}
                              {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                              {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                              {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                              {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                              {
                                this.state.clickedSwitchModel == null
                                  ? null
                                  : <div>
                                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                    <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                    {this.renderLicenseSKU(model)}
                                    {this.renderTransceiverSKU()}
                                    {this.renderStackingCableRecommednations(40)}
                                  </div>
                              }

                            </div>
                              <div className='datasheetContainer'>
                                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/ms210-series-datasheet/?file">Datasheet</a>
                                <p className='datasheetDivider'> | </p>
                                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS210_Series_Installation_Guide">Installation</a>
                              </div>
                            </div>
                            : this.state.switchModel == 225 && model == this.state.switchModel
                              ? <div><div className="switchInfoSelected">
                                {this.renderSwitchInfo('Please select a model', '')}
                                {this.renderSwitchModelDetails('MS225-24', '24 × 1G', '4 × 10G SFP+', 0, null, 'Fixed Internal', 'NA', 24, 'L2 with Static Routes', null)}
                                {this.renderSwitchModelDetails('MS225-24P', '24 × 1G', '4 × 10G SFP+', 30, null, 'Fixed Internal', 'PoE+', 24, 'L2 with Static Routes', null)}
                                {this.renderSwitchModelDetails('MS225-48', '48 × 1G', '4 × 10G SFP+', 0, null, 'Fixed Internal', 'NA', 48, 'L2 with Static Routes', null)}
                                {this.renderSwitchModelDetails('MS225-48LP', '48 × 1G', '4 × 10G SFP+', 30, null, 'Fixed Internal', 'PoE+', 48, 'L2 with Static Routes', null)}
                                {this.renderSwitchModelDetails('MS225-48FP', '48 × 1G', '4 × 10G SFP+', 30, null, 'Fixed Internal', 'PoE+', 48, 'L2 with Static Routes', null)}
                                {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                                {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                                {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                                {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                                {
                                  this.state.clickedSwitchModel == null
                                    ? null
                                    : <div>
                                      <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                      <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                      {this.renderLicenseSKU(model)}
                                      {this.renderTransceiverSKU()}
                                      {this.renderStackingCableRecommednations(40)}
                                    </div>
                                }
                              </div>
                                <div className='datasheetContainer'>
                                  <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/ms225-series-datasheet/?file">Datasheet</a>
                                  <p className='datasheetDivider'> | </p>
                                  <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS225_Series_Installation_Guide">Installation</a>
                                </div>
                              </div>
                              : this.state.switchModel == 250 && model == this.state.switchModel
                                ? <div><div className="switchInfoSelected">
                                  {this.renderSwitchInfo('Please select a model', '')}
                                  {this.renderSwitchModelDetails('MS250-24', '24 × 1G', '4 × 10G SFP+', 0, null, '1 x MA-PWR-250WAC', 'NA', 24, 'L3', null)}
                                  {this.renderSwitchModelDetails('MS250-24P', '24 × 1G', '4 × 10G SFP+', 30, null, '1 x MA-PWR-640WAC', 'PoE+', 24, 'L3', null)}
                                  {this.renderSwitchModelDetails('MS250-48', '48 × 1G', '4 × 10G SFP+', 0, null, '1 x MA-PWR-250WAC', 'NA', 48, 'L3', null)}
                                  {this.renderSwitchModelDetails('MS250-48LP', '48 × 1G', '4 × 10G SFP+', 30, null, '1 x MA-PWR-640WAC', 'PoE+', 48, 'L3', null)}
                                  {this.renderSwitchModelDetails('MS250-48FP', '48 × 1G', '4 × 10G SFP+', 30, null, '1 x MA-PWR-1025WAC', 'PoE+', 48, 'L3', null)}
                                  {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                                  {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                                  {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                                  {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                                  {
                                    this.state.clickedSwitchModel == null
                                      ? null
                                      : <div>
                                        <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                        <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                        {this.renderLicenseSKU(model)}
                                        {this.renderTransceiverSKU()}
                                        {this.renderStackingCableRecommednations(40)}
                                        {
                                          this.state.rpsu != true
                                            ? null
                                            : this.renderClickedSwitchModelPSU()
                                        }
                                      </div>
                                  }
                                </div>
                                  <div className='datasheetContainer'>
                                    <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/ms250-series-datasheet/?file">Datasheet</a>
                                    <p className='datasheetDivider'> | </p>
                                    <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS250_Series_Installation_Guide">Installation</a>
                                  </div>
                                </div>
                                : this.state.switchModel == 350 && model == this.state.switchModel
                                  ? <div><div className="switchInfoSelected">
                                    {this.renderSwitchInfo('Please select a model', '')}
                                    {this.renderSwitchModelDetails('MS350-24', '24 × 1G', '4 × 10G SFP+', 0, null, '1 x MA-PWR-250WAC', 'NA', 24, 'L3', null)}
                                    {this.renderSwitchModelDetails('MS350-24P', '24 × 1G', '4 × 10G SFP+', 30, null, '1 x MA-PWR-640WAC', 'PoE+', 24, 'L3', null)}
                                    {this.renderSwitchModelDetails('MS350-24X', '8 × 10G, 16 × 1G', '4 × 10G SFP+', 60, 10, '1 x MA-PWR-1025WAC', 'UPoE', 24, 'L3', null)}
                                    {this.renderSwitchModelDetails('MS350-48', '48 × 1G', '4 × 10G SFP+', 0, null, '1 x MA-PWR-250WAC', 'NA', 48, 'L3', null)}
                                    {this.renderSwitchModelDetails('MS350-48LP', '48 × 1G', '4 × 10G SFP+', 30, null, '1 x MA-PWR-640WAC', 'PoE+', 48, 'L3', null)}
                                    {this.renderSwitchModelDetails('MS350-48FP', '48 × 1G', '4 × 10G SFP+', 30, null, '1 x MA-PWR-1025WAC', 'PoE+', 48, 'L3', null)}
                                    {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                                    {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                                    {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                                    {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                                    {
                                      this.state.clickedSwitchModel == null
                                        ? null
                                        : <div>
                                          <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                          <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                          {this.renderLicenseSKU(model)}
                                          {this.renderTransceiverSKU()}
                                          {this.renderStackingCableRecommednations(40)}
                                          {
                                            this.state.rpsu != true
                                              ? null
                                              : this.renderClickedSwitchModelPSU()
                                          }
                                        </div>
                                    }
                                  </div>
                                    <div className='datasheetContainer'>
                                      <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/ms350-series-datasheet/?file">Datasheet</a>
                                      <p className='datasheetDivider'> | </p>
                                      <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS350_Series_Installation_Guide">Installation</a>
                                    </div>
                                  </div>
                                  : this.state.switchModel == 355 && model == this.state.switchModel
                                    ? <div><div className="switchInfoSelected">
                                      {this.renderSwitchInfo('Please select a model', '')}
                                      {this.renderSwitchModelDetails('MS355-24X', '8 × 10G, 16 × 1G', '2 × 40G QSFP+, 4 × 10G SFP+', 60, 10, '1 x MA-PWR-1025WAC', 'UPoE', 24, 'L3', null)}
                                      {this.renderSwitchModelDetails('MS355-24X2', '24 × 10G', '2 × 40G QSFP+, 4 × 10G SFP+', 60, 10, '1 x MA-PWR-1025WAC', 'UPoE', 24, 'L3', null)}
                                      {this.renderSwitchModelDetails('MS355-48X', '16 × 10G, 32 × 1G', '2 × 40G QSFP+, 4 × 10G SFP+', 60, 10, '1 x MA-PWR-1025WAC', 'UPoE', 48, 'L3', null)}
                                      {this.renderSwitchModelDetails('MS355-48X2', '24 × 10G, 24 × 1G', '2 × 40G QSFP+, 4 × 10G SFP+', 60, 10, '1 x MA-PWR-1025WAC', 'UPoE', 48, 'L3', null)}
                                      {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                                      {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                                      {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                                      {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                                      {
                                        this.state.clickedSwitchModel == null
                                          ? null
                                          : <div>
                                            <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                            <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                            {this.renderLicenseSKU(model)}
                                            {this.renderTransceiverSKU()}
                                            {this.renderStackingCableRecommednations(100)}
                                            {
                                              this.state.rpsu != true
                                                ? null
                                                : this.renderClickedSwitchModelPSU()
                                            }
                                          </div>
                                      }
                                    </div>
                                      <div className='datasheetContainer'>
                                        <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://meraki.cisco.com/product-collateral/ms355-series-datasheet/?file">Datasheet</a>
                                        <p className='datasheetDivider'> | </p>
                                        <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS355_Series_Installation_Guide">Installation</a>
                                      </div>
                                    </div>
                                    : this.state.switchModel == 390 && model == this.state.switchModel
                                      ? <div><div className="switchInfoSelected">
                                        {this.renderSwitchInfo('Please select a model', '')}
                                        {this.renderSwitchModelDetails('MS390-24', '24 x 1G', 'Modular', 0, 0, '1 x MA-PWR-350WAC', 'NA', 24, 'L3', null)}
                                        {this.renderSwitchModelDetails('MS390-24P', '24 x 1G', 'Modular', 30, 0, '1 x MA-PWR-715WAC', 'PoE+', 24, 'L3', null)}
                                        {this.renderSwitchModelDetails('MS390-24U', '24 x 1G', 'Modular', 60, 0, '1 x MA-PWR-1100WAC', 'UPoE', 24, 'L3', null)}
                                        {this.renderSwitchModelDetails('MS390-24UX', '24 x 100M/1G/2.5G/5G/10G', 'Modular', 60, 10, '1 x MA-PWR-1100WAC', 'UPoE', 24, 'L3', null)}
                                        {this.renderSwitchModelDetails('MS390-48', '48 x 1G', 'Modular', 0, 0, '1 x MA-PWR-350WAC', 'NA', 48, 'L3', null)}
                                        {this.renderSwitchModelDetails('MS390-48P', '48 x 1G', 'Modular', 30, 0, '1 x MA-PWR-715WAC', 'PoE+', 48, 'L3', null)}
                                        {this.renderSwitchModelDetails('MS390-48U', '48 x 1G', 'Modular', 60, 0, '1 x MA-PWR-1100WAC', 'UPoE', 48, 'L3', null)}
                                        {this.renderSwitchModelDetails('MS390-48UX', '36 x 2.5G + 12 x 10G', 'Modular', 60, 10, '1 x MA-PWR-1100WAC', 'UPoE', 48, 'L3', null)}
                                        {this.renderSwitchModelDetails('MS390-48UX2', '48 x 5G', 'Modular', 60, 5, '1 x MA-PWR-1100WAC', 'UPoE', 48, 'L3', null)}
                                        {
                                          this.state.clickedSwitchModel == null
                                            ? null
                                            : <div>
                                              {this.renderSwitchInfo('Please select an uplink module', "")}
                                              {this.renderUplinkModules('2 x 40G', '1 x MA-MOD-2X40G')}
                                              {this.renderUplinkModules('8 x 10G', '1 x MA-MOD-8X10G')}
                                            </div>
                                        }

                                        {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                                        {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                                        {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                                        {
                                          this.state.clickedSwitchModel == null
                                            ? null
                                            : <div>

                                              <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                              <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                              {this.renderLicenseSKU(model)}
                                              {this.renderTransceiverSKU()}
                                              {this.state.clickedUplinkSKU}
                                              {this.renderStackingCableRecommednations(120)}
                                              {this.renderStackPowerCableRecommednations()}
                                              {
                                                this.state.rpsu != true
                                                  ? null
                                                  : this.renderClickedSwitchModelPSU()
                                              }
                                            </div>
                                        }
                                      </div>
                                        <div className='datasheetContainer'>
                                          <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/MS390_Datasheet?file">Datasheet</a>
                                          <p className='datasheetDivider'> | </p>
                                          <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS390_Series_Installation_Guide">Installation</a>
                                        </div>
                                      </div>
                                      : this.state.switchModel == 410 && model == this.state.switchModel
                                        ? <div><div className="switchInfoSelected">
                                          {this.renderSwitchInfo('Please select a model', '')}
                                          {this.renderSwitchModelDetails('MS410-16', '16 × 1G SFP', '2 × 10G SFP+', 0, 0, '1 x MA-PWR-250WAC', 'NA', 16, 'L3', null)}
                                          {this.renderSwitchModelDetails('MS410-32', '32 × 1G SFP', '4 × 10G SFP+', 0, 0, '1 x MA-PWR-250WAC', 'NA', 32, 'L3', null)}
                                          {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                                          {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                                          {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                                          {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                                          {
                                            this.state.clickedSwitchModel == null
                                              ? null
                                              : <div>
                                                <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                                {this.renderLicenseSKU(model)}
                                                {this.renderTransceiverSKU()}
                                                {this.renderStackingCableRecommednations(40)}
                                                {
                                                  this.state.rpsu != true
                                                    ? null
                                                    : this.renderClickedSwitchModelPSU()
                                                }
                                              </div>
                                          }
                                        </div>
                                          <div className='datasheetContainer'>
                                            <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/MS410_Overview_and_Specifications">Datasheet</a>
                                            <p className='datasheetDivider'> | </p>
                                            <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS410_Series_Installation_Guide">Installation</a>
                                          </div>
                                        </div>
                                        : this.state.switchModel == 425 && model == this.state.switchModel
                                          ? <div><div className="switchInfoSelected">
                                            {this.renderSwitchInfo('Please select a model', '')}
                                            {this.renderSwitchModelDetails('MS425-16', '16 × 10G SFP+', '2 × 40G QSFP+', 0, 0, '1 x MA-PWR-250WAC', 'NA', 16, 'L3', null)}
                                            {this.renderSwitchModelDetails('MS425-32', '32 × 10G SFP+', '2 × 40G QSFP+', 0, 0, '1 x MA-PWR-250WAC', 'NA', 32, 'L3', null)}
                                            {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                                            {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                                            {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                                            {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                                            {
                                              this.state.clickedSwitchModel == null
                                                ? null
                                                : <div>
                                                  <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                  <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                                  {this.renderLicenseSKU(model)}
                                                  {this.renderTransceiverSKU()}
                                                  {this.renderStackingCableRecommednations(40)}
                                                  {
                                                    this.state.rpsu != true
                                                      ? null
                                                      : this.renderClickedSwitchModelPSU()
                                                  }
                                                </div>
                                            }
                                          </div>
                                            <div className='datasheetContainer'>
                                              <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/MS425_Overview_and_Specifications">Datasheet</a>
                                              <p className='datasheetDivider'> | </p>
                                              <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS425_Series_Installation_Guide">Installation</a>
                                            </div>
                                          </div>
                                          : this.state.switchModel == 450 && model == this.state.switchModel
                                            ? <div><div className="switchInfoSelected">
                                              {this.renderSwitchInfo('Please select a model', '')}
                                              {this.renderSwitchModelDetails('MS450-12', '12 × 40G QSFP+', '2 × 100G QSFP28', 0, 0, '1 x MA-PWR-250WAC', 'NA', 12)}
                                              {this.renderSwitchInfo('Access', this.state.clickedSwitchModelAccess)}
                                              {this.renderSwitchInfo('Uplinks', this.state.clickedSwitchModelUplinks)}
                                              {this.renderSwitchInfo('PoE', this.state.clickedSwitchModelPOE)}
                                              {this.renderSwitchInfo('Switching Capability', this.state.clickedSwitchModelCapability)}
                                              {
                                                this.state.clickedSwitchModel == null
                                                  ? null
                                                  : <div>
                                                    <h6 className='switchInfoHeader margin10 orderingInformation'>Ordering Information:</h6>
                                                    <p>{'1 x ' + this.state.clickedSwitchModel + '-HW'}</p>
                                                    {this.renderLicenseSKU(model)}
                                                    {this.renderTransceiverSKU()}
                                                    {this.renderStackingCableRecommednations(100)}
                                                    {
                                                      this.state.rpsu != true
                                                        ? null
                                                        : this.renderClickedSwitchModelPSU()
                                                    }
                                                  </div>
                                              }
                                            </div>
                                              <div className='datasheetContainer'>
                                                <a className='datasheet' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/MS450_Overview_and_Specifications">Datasheet</a>
                                                <p className='datasheetDivider'> | </p>
                                                <a className='installation' target="_blank" rel="noopener noreferrer" href="https://documentation.meraki.com/MS/MS_Installation_Guides/MS450_Series_Installation_Guide">Installation</a>
                                              </div>
                                            </div>
                                            : ('')
        }
      </div>
    </div >)
  }

  switchPickingLogic() {
    return (<div>
      {
        (<div>
          {
            switches.map(
              switchmodel => this.state.poe == true && switchmodel.poe == 0
                ? ''
                : this.state.poeType > switchmodel.poe
                  ? ''
                  : this.state.stacking == true && switchmodel.stacking != true
                    ? ''
                    : this.state.capability == 3 && switchmodel.capability != 3
                      ? ''
                      : this.state.stackPower == true && switchmodel.stackPower != true
                        ? ''
                        : this.state.ruggedized == true && switchmodel.ruggedized != true
                          ? ''
                          : switchmodel.uplink != this.state.uplink && switchmodel.uplink2 != this.state.uplink && this.state.uplink != null
                            ? ''
                            : this.state.mgig == true && switchmodel.mgig != true
                              ? ''
                              : this.state.rpsu == true && switchmodel.rpsu != true
                                ? ''
                                : this.state.rfan == true && switchmodel.rfan != true
                                  ? ''
                                  // Enable advanced license for specific models
                                  : this.state.license == 'A' && switchmodel.model != 390 && switchmodel.model != 150 && switchmodel.model != 9300 && switchmodel.model != '9300X' && switchmodel.model != '9300L'
                                    ? ''
                                    : this.renderSwitchModel(switchmodel.model))
          }
        </div>)
      }
    </div>)
  }

  optionBubbleLogic() {
    return (<div>
      {
        this.state.poe != null && this.state.poeType == 30
          ? <div className='optionBubble' onClick={() => this.setState({ poe: null, poeType: null })}>{'PoE+'}</div>
          : this.state.poe != null && this.state.poeType == 60
            ? <div className='optionBubble' onClick={() => this.setState({ poe: null, poeType: null })}>{'UPoE'}</div>
            : this.state.poe != null && this.state.poeType == 90
              ? <div className='optionBubble' onClick={() => this.setState({ poe: null, poeType: null })}>{'UPoE+'}</div>
              : this.state.poe != null
                ? <div className='optionBubble' onClick={() => this.setState({ poe: null, poeType: null })}>{'PoE'}</div>
                : ''
      }
      {
        this.state.stacking == true
          ? <div className='optionBubble' onClick={() => this.setState({ stacking: null, stackSize: null })}>Stacking</div>
          : ''
      }
      {
        this.state.mgig == true
          ? <div className='optionBubble' onClick={() => this.setState({ mgig: null, mgigSpeed: null })}>mGig</div>
          : ''
      }
      {
        this.state.capability == 3
          ? <div className='optionBubble' onClick={() => this.setState({ capability: null })}>{'Layer ' + this.state.capability}</div>
          : ''
      }
      {
        this.state.stackPower == true
          ? <div className='optionBubble' onClick={() => this.setState({ stackPower: null, stackPowerSize: null })}>StackPower</div>
          : ''
      }
      {
        this.state.ruggedized == true
          ? <div className='optionBubble' onClick={() => this.setState({ ruggedized: null })}>Ruggedized</div>
          : ''
      }
      {
        this.state.license == 'E' && this.state.licenseTerm != null
          ? <div className='optionBubble' onClick={() => this.setState({ license: null, licenseTerm: null })}>{'License: ' + 'ENT ' + this.state.licenseTerm + ' YR'}</div>
          : this.state.license == 'E'
            ? <div className='optionBubble' onClick={() => this.setState({ license: null, licenseTerm: null })}>{'License: ENT'}</div>
            : ''
      }
      {
        this.state.license == 'A' && this.state.licenseTerm != null
          ? <div className='optionBubble' onClick={() => this.setState({ license: null, licenseTerm: null })}>{'License: ' + 'ADV ' + this.state.licenseTerm + ' YR'}</div>
          : this.state.license == 'A'
            ? <div className='optionBubble' onClick={() => this.setState({ license: null, licenseTerm: null })}>{'License: ADV'}</div>
            : ''
      }
      {
        this.state.uplink != null
          ? <div className='optionBubble' onClick={() => this.setState({ uplink: null })}>{'Uplinks: ' + this.state.uplink + ' Gbps'}</div>
          : ''
      }

      {
        this.state.rpsu != null
          ? <div className='optionBubble' onClick={() => this.setState({ rpsu: null })}>{'Redundant PSU'}</div>
          : ''
      }
      {
        this.state.rfan != null
          ? <div className='optionBubble' onClick={() => this.setState({ rfan: null })}>{'Redundant Fan'}</div>
          : ''
      }
    </div>)
  }

  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ OPTIONS @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@

  renderOptionStacking() {
    return (<div>
      <div onClick={(
      ) => this.state.stacking != true
          ? this.setState({ stacking: true })
          : this.setState({ stacking: null, stackSize: null })} className={this.state.stacking
            ? 'optionClicked'
            : 'option'}>
        Stacking
      </div>
      {
        this.state.stacking == true
          ? (<div className='secondaryOptionsContainer'>
            <h4>How many switches will be in the stack?</h4>
            <h5>Physical Stacking helps provide easy management and physical redundancy. Utilizing two physical stacking ports on the back of each switch, a stack can provide for gateway redundancy at layer 3 and dual-homing redundancy at layer 2.</h5>
            <div className='secondaryOptionsContainer2'>
              <div onClick={(
              ) => this.state.stackSize != 2
                  ? this.setState({ stackSize: 2 })
                  : this.setState({ stackSize: null })} className={this.state.stackSize == 2
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>2</h4>
              </div>
              <div onClick={(
              ) => this.state.stackSize != 3
                  ? this.setState({ stackSize: 3 })
                  : this.setState({ stackSize: null })} className={this.state.stackSize == 3
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>3</h4>
              </div>
              <div onClick={(
              ) => this.state.stackSize != 4
                  ? this.setState({ stackSize: 4 })
                  : this.setState({ stackSize: null })} className={this.state.stackSize == 4
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>4</h4>
              </div>
              <div onClick={(
              ) => this.state.stackSize != 5
                  ? this.setState({ stackSize: 5 })
                  : this.setState({ stackSize: null })} className={this.state.stackSize == 5
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>5</h4>
              </div>
              <div onClick={(
              ) => this.state.stackSize != 6
                  ? this.setState({ stackSize: 6 })
                  : this.setState({ stackSize: null })} className={this.state.stackSize == 6
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>6</h4>
              </div>
              <div onClick={(
              ) => this.state.stackSize != 7
                  ? this.setState({ stackSize: 7 })
                  : this.setState({ stackSize: null })} className={this.state.stackSize == 7
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>7</h4>
              </div>
              <div onClick={(
              ) => this.state.stackSize != 8
                  ? this.setState({ stackSize: 8 })
                  : this.setState({ stackSize: null })} className={this.state.stackSize == 8
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>8</h4>
              </div>
            </div>
          </div>)
          : (<div></div>)
      }
    </div>)
  }

  renderOptionMgig() {
    return (<div>
      <div onClick={() => {
        if (this.state.mgig != true) {
          this.setState({
            mgig: true,
            mgigSpeed: 1,
            clickedSwitchModel: null,
            clickedSwitchModelAccess: null,
            clickedSwitchModelUplinks: null,
            clickedSwitchModelPSU: null,
            clickedSwitchModelPOE: null,
            clickedSwitchModelPortCount: null,
            clickedSwitchModelCapability: null,
            clickedSwitchModelTemp: null,
            clickedUplinkModule: null,
            clickedUplinkSKU: null,
            clickedSwitchModelRuggedized: null
          });
        } else {
          this.setState({
            mgig: null,
            mgigSpeed: null
          });
        }
      }} className={this.state.mgig ? 'optionClicked' : 'option'}>
        Multigigabit
      </div>
      {
        this.state.mgig == true
          ? (<div className='secondaryOptionsContainer'>
            <h4>What mGig speed do you need?</h4>
            <h5>Multigigabit ethernet is a new technology based on the work of the NBASE-T alliance that allows customers to use existing twisted-pair ethernet to drive speeds well beyond 1 Gbps.</h5>
            <div className='secondaryOptionsContainer2'>

              <div onClick={() => {
                if (this.state.mgigSpeed !== 2.5) {
                  this.setState({
                    mgig: true, mgigSpeed: 2.5,
                    clickedSwitchModel: null,
                    clickedSwitchModelAccess: null,
                    clickedSwitchModelUplinks: null,
                    clickedSwitchModelPSU: null,
                    clickedSwitchModelPOE: null,
                    clickedSwitchModelPortCount: null,
                    clickedSwitchModelCapability: null,
                    clickedSwitchModelTemp: null,
                    clickedUplinkModule: null,
                    clickedUplinkSKU: null,
                    clickedSwitchModelRuggedized: null
                  });
                } else {
                  this.setState({ mgigSpeed: 1 });
                }
              }} className={this.state.mgigSpeed === 2.5 ? 'secondaryOptionClicked' : 'secondaryOption'}>
                <h4>2.5 Gbps</h4>
              </div>

              <div onClick={() => {
                if (this.state.mgigSpeed !== 5) {
                  this.setState({
                    mgig: true,
                    mgigSpeed: 5,
                    clickedSwitchModel: null,
                    clickedSwitchModelAccess: null,
                    clickedSwitchModelUplinks: null,
                    clickedSwitchModelPSU: null,
                    clickedSwitchModelPOE: null,
                    clickedSwitchModelPortCount: null,
                    clickedSwitchModelCapability: null,
                    clickedSwitchModelTemp: null,
                    clickedUplinkModule: null,
                    clickedUplinkSKU: null,
                    clickedSwitchModelRuggedized: null
                  });
                } else {
                  this.setState({ mgigSpeed: 1 });
                }
              }} className={this.state.mgigSpeed === 5 ? 'secondaryOptionClicked' : 'secondaryOption'}>
                <h4>5 Gbps</h4>
              </div>

              <div onClick={() => {
                if (this.state.mgigSpeed !== 10) {
                  this.setState({
                    mgig: true,
                    mgigSpeed: 10,
                    clickedSwitchModel: null,
                    clickedSwitchModelAccess: null,
                    clickedSwitchModelUplinks: null,
                    clickedSwitchModelPSU: null,
                    clickedSwitchModelPOE: null,
                    clickedSwitchModelPortCount: null,
                    clickedSwitchModelCapability: null,
                    clickedSwitchModelTemp: null,
                    clickedUplinkModule: null,
                    clickedUplinkSKU: null,
                    clickedSwitchModelRuggedized: null
                  });
                } else {
                  this.setState({ mgigSpeed: 1 });
                }
              }} className={this.state.mgigSpeed === 10 ? 'secondaryOptionClicked' : 'secondaryOption'}>
                <h4>10 Gbps</h4>
              </div>
            </div>
          </div>)
          : (<div></div>)
      }
    </div>)
  }

  renderOptionPoe() {
    return (<div>
      <div onClick={(
      ) => this.state.poe != true
          ? this.setState({ poe: true })
          : this.setState({ poe: null, poeType: null })} className={this.state.poe == true
            ? 'optionClicked'
            : 'option'
          }>
        PoE
      </div>
      {
        this.state.poe == true
          ? (<div className='secondaryOptionsContainer'>
            <h4>What type of PoE do you need?</h4>
            <h5>Power over Ethernet allows individual ports on the switch to provide electrical power for devices that may need it.</h5>
            <div className='secondaryOptionsContainer2'>
              <div onClick={(
              ) => this.state.poeType != 30
                  ? this.setState({ poeType: 30 })
                  : this.setState({ poeType: null })} className={this.state.poeType == 30
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>PoE+</h4>
              </div>
              <div onClick={(
              ) => this.state.poeType != 60
                  ? this.setState({ poeType: 60 })
                  : this.setState({ poeType: null })} className={this.state.poeType == 60
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>UPoE</h4>
              </div>
              <div onClick={(
              ) => this.state.poeType != 90
                  ? this.setState({ poeType: 90 })
                  : this.setState({ poeType: null })} className={this.state.poeType == 90
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>UPoE+</h4>
              </div>
            </div>
          </div>)
          : (<div></div>)
      }
    </div>)
  }

  renderOptionL3() {
    return (<div>
      <div onClick={(
      ) => this.state.capability != 3
          ? this.setState({ capability: 3 })
          : this.setState({ capability: null })} className={this.state.capability
            ? 'optionClicked'
            : 'option'}>
        Layer 3
      </div>
    </div>)
  }

  renderOptionstackPower() {
    return (<div>
      <div onClick={(
      ) => this.state.stackPower != true
          ? this.setState({ stackPower: true })
          : this.setState({ stackPower: null })} className={this.state.stackPower
            ? 'optionClicked'
            : 'option'}>
        StackPower
      </div>
      {
        this.state.stackPower == true
          ? (<div className='secondaryOptionsContainer'>
            <h4>How many switches will be in the StackPower stack?</h4>
            <h5>StackPower provides simple and resilient power distribution across the stack by pooling & distributing power across MS390s using a series of StackPower cables.</h5>
            <div className='secondaryOptionsContainer2'>
              <div onClick={(
              ) => this.state.stackPowerSize != 2
                  ? this.setState({ stackPowerSize: 2 })
                  : this.setState({ stackPowerSize: null })} className={this.state.stackPowerSize == 2
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>2</h4>
              </div>
              <div onClick={(
              ) => this.state.stackPowerSize != 3
                  ? this.setState({ stackPowerSize: 3 })
                  : this.setState({ stackPowerSize: null })} className={this.state.stackPowerSize == 3
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>3</h4>
              </div>
              <div onClick={(
              ) => this.state.stackPowerSize != 4
                  ? this.setState({ stackPowerSize: 4 })
                  : this.setState({ stackPowerSize: null })} className={this.state.stackPowerSize == 4
                    ? 'secondaryOptionClicked'
                    : 'secondaryOption'}>
                <h4>4</h4>
              </div>
            </div>
          </div>)
          : (<div></div>)
      }
    </div>)
  }

  renderOptionRuggedized() {
    return (<div>
      <div onClick={(
      ) => this.state.ruggedized != true
          ? this.setState({ ruggedized: true })
          : this.setState({ ruggedized: null })} className={this.state.ruggedized
            ? 'optionClicked'
            : 'option'}>
        Ruggedized
      </div>
    </div>)
  }

  renderOption1() {
    return (<div>
      <div onClick={(
      ) => this.state.uplink != 1
          ? this.setState({ uplink: 1, transceiver: null })
          : this.setState({ uplink: null })} className={this.state.uplink == 1
            ? 'optionClicked'
            : 'option'
          }>

        <div className='leftSide'>1 Gbps</div>
        <div className='rightSide'>SFP</div>
      </div>

      {/* {
        this.state.uplink == 1
          ? this.renderSFPOptions()
          : ""
      } */}

    </div>)
  }
  renderOption10() {
    return (<div>
      <div onClick={(
      ) => this.state.uplink != 10
          ? this.setState({ uplink: 10, transceiver: null })
          : this.setState({ uplink: null })} className={this.state.uplink == 10
            ? 'optionClicked'
            : 'option'
          }>
        <div className='leftSide'>10 Gbps</div>
        <div className='rightSide'>SFP+</div>
      </div>
      {/* {
        this.state.uplink == 10
          ? this.renderSFPOptions()
          : ""
      } */}
    </div>)
  }
  renderOption40() {
    return (<div>
      <div onClick={(
      ) => this.state.uplink != 40
          ? this.setState({ uplink: 40, transceiver: null })
          : this.setState({ uplink: null })} className={this.state.uplink == 40
            ? 'optionClicked'
            : 'option'
          }>
        <div className='leftSide'>40 Gbps</div>
        <div className='rightSide'>QSFP+</div>
      </div>
      {/* {
        this.state.uplink == 40
          ? this.renderSFPOptions()
          : ""
      } */}
    </div>)
  }
  renderOption100() {
    return (<div>
      <div onClick={(
      ) => this.state.uplink != 100
          ? this.setState({ uplink: 100, transceiver: null })
          : this.setState({ uplink: null })} className={this.state.uplink == 100
            ? 'optionClicked'
            : 'option'
          }>
        <div className='leftSide'>100 Gbps</div>
        <div className='rightSide'>QSFP28</div>
      </div>
      {/* {
        this.state.uplink == 100
          ? this.renderSFPOptions()
          : ""
      } */}
    </div>)
  }

  // renderSFPOptions() {
  //   return (<div className="secondaryOptionsContainer">
  //     <h4>Please select a fiber transceivers:</h4>
  //     <div>
  //       {
  //         this.state.uplink == 1
  //           ? <div>
  //             <div onClick={(
  //             ) => this.state.transceiver != 'MA-SFP-1GB-TX'
  //                 ? this.setState({ transceiver: 'MA-SFP-1GB-TX' })
  //                 : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-1GB-TX'
  //                   ? 'secondaryOptionClicked'
  //                   : 'secondaryOption'}>
  //               <div className='secondaryLeftSide'>MA-SFP-1GB-TX</div>
  //               <div className='secondaryRightSide'>Ethernet</div>
  //             </div>
  //             <div onClick={(
  //             ) => this.state.transceiver != 'MA-SFP-1GB-SX'
  //                 ? this.setState({ transceiver: 'MA-SFP-1GB-SX' })
  //                 : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-1GB-SX'
  //                   ? 'secondaryOptionClicked'
  //                   : 'secondaryOption'}>
  //               <div className='secondaryLeftSide'>MA-SFP-1GB-SX (1km)</div>
  //               <div className='secondaryRightSide'>MMF</div>
  //             </div>
  //             <div onClick={(
  //             ) => this.state.transceiver != 'MA-SFP-1GB-LX10'
  //                 ? this.setState({ transceiver: 'MA-SFP-1GB-LX10' })
  //                 : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-1GB-LX10'
  //                   ? 'secondaryOptionClicked'
  //                   : 'secondaryOption'}>
  //               <div className='secondaryLeftSide'>MA-SFP-1GB-LX10 (550km/10km)</div>
  //               <div className='secondaryRightSide'>MMF/SMF</div>
  //             </div>
  //           </div>

  //           : this.state.uplink == 10
  //             ? <div>
  //               <div onClick={(
  //               ) => this.state.transceiver != 'MA-SFP-1GB-TX'
  //                   ? this.setState({ transceiver: 'MA-SFP-1GB-TX' })
  //                   : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-1GB-TX'
  //                     ? 'secondaryOptionClicked'
  //                     : 'secondaryOption'}>
  //                 <div className='secondaryLeftSide'>MA-SFP-1GB-TX</div>
  //                 <div className='secondaryRightSide'>Ethernet</div>
  //               </div>
  //               <div onClick={(
  //               ) => this.state.transceiver != 'MA-SFP-10GB-SR'
  //                   ? this.setState({ transceiver: 'MA-SFP-10GB-SR' })
  //                   : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-10GB-SR'
  //                     ? 'secondaryOptionClicked'
  //                     : 'secondaryOption'}>
  //                 <div className='secondaryLeftSide'>MA-SFP-10GB-SR (400m)</div>
  //                 <div className='secondaryRightSide'>MMF</div>
  //               </div>
  //               <div onClick={(
  //               ) => this.state.transceiver != 'MA-SFP-10GB-LRM'
  //                   ? this.setState({ transceiver: 'MA-SFP-10GB-LRM' })
  //                   : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-10GB-LRM'
  //                     ? 'secondaryOptionClicked'
  //                     : 'secondaryOption'}>
  //                 <div className='secondaryLeftSide'>MA-SFP-10GB-LRM (220m/220m)</div>
  //                 <div className='secondaryRightSide'>MMF/SMF</div>
  //               </div>
  //               <div onClick={(
  //               ) => this.state.transceiver != 'MA-SFP-10GB-LR'
  //                   ? this.setState({ transceiver: 'MA-SFP-10GB-LR' })
  //                   : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-SFP-10GB-LR'
  //                     ? 'secondaryOptionClicked'
  //                     : 'secondaryOption'}>
  //                 <div className='secondaryLeftSide'>MA-SFP-10GB-LR (10km)</div>
  //                 <div className='secondaryRightSide'>SMF</div>
  //               </div>
  //             </div>
  //             : this.state.uplink == 40
  //               ? <div>
  //                 <div onClick={(
  //                 ) => this.state.transceiver != 'MA-QSFP-40G-SR4'
  //                     ? this.setState({ transceiver: 'MA-QSFP-40G-SR4' })
  //                     : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-40G-SR4'
  //                       ? 'secondaryOptionClicked'
  //                       : 'secondaryOption'}>
  //                   <div className='secondaryLeftSide'>MA-QSFP-40G-SR4 (150m)</div>
  //                   <div className='secondaryRightSide'>MMF</div>
  //                 </div>
  //                 <div onClick={(
  //                 ) => this.state.transceiver != 'MA-QSFP-40G-CSR4'
  //                     ? this.setState({ transceiver: 'MA-QSFP-40G-CSR4' })
  //                     : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-40G-CSR4'
  //                       ? 'secondaryOptionClicked'
  //                       : 'secondaryOption'}>
  //                   <div className='secondaryLeftSide'>MA-QSFP-40G-CSR4 (400m)</div>
  //                   <div className='secondaryRightSide'>MMF</div>
  //                 </div>
  //                 <div onClick={(
  //                 ) => this.state.transceiver != 'MA-QSFP-40G-SR-BD'
  //                     ? this.setState({ transceiver: 'MA-QSFP-40G-SR-BD' })
  //                     : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-40G-SR-BD'
  //                       ? 'secondaryOptionClicked'
  //                       : 'secondaryOption'}>
  //                   <div className='secondaryLeftSide'>MA-QSFP-40G-SR-BD (150m)</div>
  //                   <div className='secondaryRightSide'>MMF</div>
  //                 </div>
  //                 <div onClick={(
  //                 ) => this.state.transceiver != 'MA-QSFP-40G-LR4'
  //                     ? this.setState({ transceiver: 'MA-QSFP-40G-LR4' })
  //                     : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-40G-LR4'
  //                       ? 'secondaryOptionClicked'
  //                       : 'secondaryOption'}>
  //                   <div className='secondaryLeftSide'>MA-QSFP-40G-LR4 (10km)</div>
  //                   <div className='secondaryRightSide'>SMF</div>
  //                 </div>
  //               </div>
  //               : this.state.uplink == 100
  //                 ? <div>
  //                   <div onClick={(
  //                   ) => this.state.transceiver != 'MA-QSFP-100G-SR4'
  //                       ? this.setState({ transceiver: 'MA-QSFP-100G-SR4' })
  //                       : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-100G-SR4'
  //                         ? 'secondaryOptionClicked'
  //                         : 'secondaryOption'}>
  //                     <div className='secondaryLeftSide'>MA-QSFP-100G-SR4 (100m)</div>
  //                     <div className='secondaryRightSide'>MMF</div>
  //                   </div>
  //                   <div onClick={(
  //                   ) => this.state.transceiver != 'MA-QSFP-100G-LR4'
  //                       ? this.setState({ transceiver: 'MA-QSFP-100G-LR4' })
  //                       : this.setState({ transceiver: null })} className={this.state.transceiver == 'MA-QSFP-100G-LR4'
  //                         ? 'secondaryOptionClicked'
  //                         : 'secondaryOption'}>
  //                     <div className='secondaryLeftSide'>MA-QSFP-100G-LR4 (10km)</div>
  //                     <div className='secondaryRightSide'>SMF</div>
  //                   </div>
  //                 </div>
  //                 : ""
  //       }
  //     </div>
  //   </div>)
  // }

  renderOptionPSU() {
    return (<div>
      <div onClick={(
      ) => this.state.rpsu != true
          ? this.setState({ rpsu: true })
          : this.setState({ rpsu: null })} className={this.state.rpsu == true
            ? 'optionClicked'
            : 'option'
          }>
        <div className='leftSide'>Power Supply</div>
        <div className='rightSide'>Hot-Swappable</div>
      </div>
    </div>)
  }

  renderOptionFan() {
    return (<div>
      <div onClick={(
      ) => this.state.rfan != true
          ? this.setState({ rfan: true })
          : this.setState({ rfan: null })} className={this.state.rfan == true
            ? 'optionClicked'
            : 'option'
          }>
        <div className='leftSide'>Fan</div>
        <div className='rightSide'>Hot-Swappable</div>
      </div>
    </div>)
  }
  renderOptionEnterprise() {
    return (<div>
      <div onClick={(
      ) => this.state.license != 'E'
          ? this.setState({ license: 'E', licenseTerm: null })
          : this.setState({ license: null, licenseTerm: null })} className={this.state.license == 'E'
            ? 'optionClicked'
            : 'option'}>
        Enterprise
      </div>
      {
        this.state.license == 'E'
          ? (<div className='secondaryOptionsContainer'>
            <h4>Please select the duration for your Enterprise license (Years)</h4>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryLicenseOptions(1)}
              {this.renderSecondaryLicenseOptions(3)}
              {this.renderSecondaryLicenseOptions(5)}
              {this.renderSecondaryLicenseOptions(7)}
              {this.renderSecondaryLicenseOptions(10)}
            </div>
          </div>)
          : (<div></div>)
      }
    </div>)
  }

  renderOptionAdvanced() {
    return (<div>
      <div onClick={(
      ) => this.state.license != 'A'
          ? this.setState({ license: 'A', licenseTerm: null })
          : this.setState({ license: null, licenseTerm: null })} className={this.state.license == 'A'
            ? 'optionClicked'
            : 'option'}>
        Advanced
      </div>
      {
        this.state.license == 'A'
          ? (<div className='secondaryOptionsContainer'>
            <h4>Please select the duration for your Advanced license (Years)</h4>
            <h5>The Advanced license enables adaptive policy and supports over 1000 routes for OSPF</h5>
            <div className="secondaryOptionsContainer2">
              {this.renderSecondaryLicenseOptions(1)}
              {this.renderSecondaryLicenseOptions(3)}
              {this.renderSecondaryLicenseOptions(5)}
              {this.renderSecondaryLicenseOptions(7)}
              {this.renderSecondaryLicenseOptions(10)}
            </div>
          </div>)
          : (<div></div>)
      }
    </div>)
  }

  renderSecondaryLicenseOptions(years) {
    return (<div onClick={(
    ) => this.state.licenseTerm != years
        ? this.setState({ licenseTerm: years })
        : this.setState({ licenseTerm: null })} className={this.state.licenseTerm == years
          ? 'secondaryOptionClicked'
          : 'secondaryOption'}>
      <h4>{years}</h4>
    </div>)
  }
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ RENDER @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  //@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
  render() {
    return (<div>

      <div className="form">

        <div className='leftForm'>
          {this.optionBubbleLogic()}
          {this.switchPickingLogic()}
        </div>

        <div className='rightFormContainer'>
          <div className='rightForm'>
            <div className="mainTitle">
              <h6 className='topSubHeader'>Meraki Networking</h6>
              <h1>MS Switches</h1>
              <h3 className='bottomSubHeader'>
                The Meraki MS switches are cloud managed access and aggregation switches, combining the benefits of cloud-based management with a powerful, reliable platform.
              </h3>
            </div>

            <div>
              <h2>Choose your license type.</h2>
              <a href="https://documentation.meraki.com/MS/MS_Overview_and_Specifications/MS390_Datasheet#MS390_Licensing" target="_blank">Learn more about our switch licensing</a>
              {this.renderOptionEnterprise()}
              {this.renderOptionAdvanced()}
            </div>

            <div>
              <h2>Choose your features.</h2>
              {this.renderOptionPoe()}
              {this.renderOptionStacking()}
              {this.renderOptionMgig()}
              {this.renderOptionL3()}
              {this.renderOptionstackPower()}
              {this.renderOptionRuggedized()}
            </div>

            <div>
              <h2>Choose your uplink speed.</h2>
              <a href="https://meraki.cisco.com/lib/pdf/meraki_datasheet_sfp.pdf" target="_blank">Learn more about our fiber transceivers</a>
              {this.renderOption1()}
              {this.renderOption10()}
              {this.renderOption40()}
              {this.renderOption100()}
            </div>

            <div>
              <h2>Which redundant parts do you need?</h2>
              {this.renderOptionPSU()}
              {this.renderOptionFan()}
            </div>

          </div>
        </div>
      </div>

    </div>);
  }
}

// ========================================

export default SwitchPicker;
